import React from 'react'
import styled from 'styled-components'

const Container = styled.div`

background-color: rgb(33, 33, 33);
height: 100vh;
color: white;
scroll-snap-align: center;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center
`
function BevriendeClubs() {
  return (
    <Container>BevriendeClubs</Container>
  )
}

export default BevriendeClubs